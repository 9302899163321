<template>
    <div class="pt-5">
        <el-button size="mini" @click="goback()">Tillbaka</el-button>
        <el-button size="mini" v-show="buttonsArray[0].isVisible" :loading="sendBtnLoading" type="primary" @click="bookInvoice()">{{ buttonsArray[0].name }}</el-button>
        <el-button size="mini" v-show="buttonsArray[1].isVisible" type="primary" :loading="saveBtnLoading" @click="saveItems()">{{ buttonsArray[1].name }}</el-button>
        <el-button size="mini" :disabled="true" v-show="buttonsArray[2].isVisible" :loading="denyBtnLoading" type="primary" @click="deny()">{{ buttonsArray[2].name }}</el-button>
        <el-button size="mini" v-show="buttonsArray[3].isVisible" type="primary" :loading="deleteBtnLoading" @click="deleteInvoice()">{{ buttonsArray[3].name }}</el-button>
        <el-button size="mini" v-show="buttonsArray[4].isVisible" type="primary" :loading="receiptBtnLoading" @click="createReceipt()">{{ buttonsArray[4].name }}</el-button>
    </div>
</template>
<script>
import Api from "../../supplierInvoice.api";
import Enum from "../../supplierInvoice.enum";
import { mapState } from "vuex";

export default {
    props: ["supplierInvoice", "disableBookingButton"],

    created() {
        this.buttonsVisibility();
    },

    watch: {
        disableBookingButton: {
            immediate: true,
            handler() {
                if (this.disableBookingButton == true) {
                    this.buttonsArray[0].isVisible = false;
                } else {
                    this.buttonsArray[0].isVisible = true;
                }
            },
        },
    },

    data() {
        return {
            buttonsArray: [
                { name: "Skicka för attest", isVisible: true },
                { name: "Spara och nästa", isVisible: true },
                { name: "Neka", isVisible: false },
                { name: "Ta bort", isVisible: true },
                { name: "Skapa kvitto", isVisible: true },
            ],
        };
    },

    methods: {
        buttonsVisibility() {
            if (this.supplierInvoice.status == Enum.CreditorInvoiceStatus.Attesteras || this.supplierInvoice.isCreditNote) {
                this.buttonsArray.forEach(button => {
                    button.isVisible = false;
                });
                this.buttonsArray[2].isVisible = true;
            } else if (
                this.supplierInvoice.status == Enum.supplierInvoiceStatusList.Exported ||
                this.supplierInvoice.status == Enum.supplierInvoiceStatusList.Utkast ||
                this.supplierInvoice.status == Enum.supplierInvoiceStatusList.NotSet
            ) {
                this.buttonsArray.forEach(button => {
                    button.isVisible = true;
                });
                this.buttonsArray[2].isVisible = false;
            } else {
                this.buttonsArray.forEach(button => {
                    button.isVisible = false;
                });
            }
        },

        bookInvoice() {
            this.$emit("bookInvoice");
        },

        deleteInvoice() {
            this.$emit("deleteInvoice");
        },

        createReceipt() {
            this.$emit("createReceipt");
        },

        removeLockItems(lockTable, id) {
            Api.removeLock(lockTable, id);
        },

        deny() {
            this.denyLoading = true;
            this.$emit("deny");
        },

        saveItems() {
            this.$emit("saveItems");
        },

        goback() {
            switch (this.supplierInvoice.status) {
                case Enum.supplierInvoiceStatusList.Exported:
                    this.removeLockItems(Enum.LockedTable.SupplierInvoiceCaptures, this.supplierInvoice.tempSupplierInvoice.supplierInvoiceCaptureId);
                    break;
                case Enum.supplierInvoiceStatusList.Utkast:
                    this.removeLockItems(Enum.LockedTable.CreditorInvoice, this.supplierInvoice.tempSupplierInvoice.tempSupplierInvoiceId);
                    break;
                default:
                    break;
            }
            this.$router.push(`/supplierInvoiceList`);
        },
    },

    computed: {
        ...mapState({
            deleteBtnLoading: state => state.supplierInvoice.deleteBtnLoading,
            saveBtnLoading: state => state.supplierInvoice.saveBtnLoading,
            denyBtnLoading: state => state.supplierInvoice.denyBtnLoading,
            sendBtnLoading: state => state.supplierInvoice.sendBtnLoading,
            receiptBtnLoading: state => state.supplierInvoice.receiptBtnLoading,
        }),
    },
};
</script>
